.Buttons-container-mobile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 0;
  /* background-color: pink; */
  justify-content: center;
}

.logo-mobile {
  
  height: 4em;
  #pointer-events: none;
  margin: 5% 6%;
  /*background-color: pink; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.Buttons-mobile {
  display: flex;
  flex-direction: column;
  margin: 0 8px 0 8px;
}

.Button-mobile {
  margin: 8px 0px 8px 0px;
  width: 8.5em;
}

.Button-slim-mobile {
  width: 7.6em;
}

.Button-wide-mobile {
  width: 8.5em;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header__mobile {
  align-items: center;
}

.Img-iphone14 {
  margin-top: 1em;
}

.elipse-mobile {
  position: absolute;
  top: -10%;
  left: 45%;
  z-index: 1;
}

.elipse2-mobile {
  position: absolute;
  bottom: 10%;
  left: -5%;
  z-index: 1;
}

.elipse3-mobile {
  position: absolute;
  bottom: 35%;
  right: -20%;
  z-index: 1;
}

.mockup-mobile {
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
}

.mockup2-mobile {
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
}

.mockup3-mobile {
  position: relative;
  top: 0em;
  left: 3%;
  z-index: 2;
}

.mockup4-mobile {
  position: relative;
  top: 0em;
  right: 0;
  z-index: 2;
}

.column-container {
  display: flex;
  flex-direction: column;
  /* font-family: Consolas, Arial, sans-serif; */
  /* background-color: pink; */
}

.flex3 {
  display: flex;
  flex: 3;
  flex-direction: column;
  /*background-color: pink; */
  /*justify-content: center;*/
  /*align-items: center; */
}

.flex2 {
  display: flex;
  flex: 2;
  flex-direction: column;
  position: relative;
  /*background-color: pink; */
  /*justify-content: center;*/
  /*align-items: center; */
}

.flex1-mobile {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  justify-content: center;
  align-items: center;
}

.align-start {
  align-items: start;
}

.mobile-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8% 8% 4% 8%;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Melper */

.melper-mobile {
  /*width: 249px;
  height: 70px; */

  /* Tablet/H1 */

  font-family: 'Druk Wide Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height, or 146% */

  /* text */

  color: #151436;
}

.melper-desktop {
  font-size: 64px;
}

/* Title  */

.title-app-mobile {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #151436;
  text-align: center;
}

.title-item-mobile {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: #151436;
}

.context-item-mobile {
  margin: 1em 0 0 0;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #151436;
}

.fm-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-context-mobile {
  margin: 0 4% 0 4%;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #151436;
}

.doc-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  /*background-color: pink;*/
  /*justify-content: center;*/
}

.contact {
  display: flex;
  flex: 1.3;
  flex-direction: column;
  /*background-color: pink;*/
  /*justify-content: center;*/
}

.about {
  width: 16em;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #151436;
}

.doc-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #151436;
}

.doc-mobile {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #151436;
  text-align: start;
}

.footer {
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #b8b8b8;
}

.footer-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #151436;
}

.html-mobile {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #151436;
  overflow: hidden;
  min-height: 25em;
}

.weight500 {
  font-weight: 500;
}

.red {
  color: #cd5b76;
}

.blue {
  color: #4d6ce6;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.mt-4 {
  margin-top: 4em;
}

.mt-7 {
  margin-top: 7em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mb-1 {
  margin-bottom: 1em;
}

.ml-1 {
  margin-left: 5%;
}

.back-mobile {
  position: absolute;
  top: 3% ;
  left: 10%;

  /*background-color: pink; */
}
