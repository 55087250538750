.button-box-tablet {
  display: flex;
  width: 6em;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 4em 3em;
}

.logo-mobile {
  #height: 40vmin;
  #pointer-events: none;
  /*margin: 5% 6%;*/
  /*background-color: pink; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-header__mobile {
  align-items: center;
}

.elipse-mobile {
  position: absolute;
  top: -10%;
  left: 45%;
  z-index: 1;
}

.elipse2-tablet {
  position: absolute;
  bottom: 30%;
  left: -10%;
  z-index: 1;
}

.elipse3-mobile {
  position: absolute;
  bottom: 35%;
  right: -20%;
  z-index: 1;
}

.mockup-mobile {
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
}

.mockup2-tablet {
  position: relative;
  top: 0;
  left: 0em;
  z-index: 2;
}

.mockup3-mobile {
  position: relative;
  top: 0em;
  left: 3%;
  z-index: 2;
}

.mockup4-mobile {
  position: relative;
  top: 0em;
  right: 0;
  z-index: 2;
}

.flex1-mobile {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  justify-content: center;
  align-items: center;
}

/* Melper */

.melper-tablet {
  /*width: 249px;
  height: 70px; */

  /* Tablet/H1 */

  font-family: 'Druk Wide Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 70px;
  /* identical to box height, or 146% */

  /* text */

  color: #151436;
}

.title-app-tablet {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #151436;
  text-align: start;
}

.title-item-table {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #151436;
}

.context-item-table {
  margin: 1em 0 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #151436;
}

.fm-context-tablet {
  margin: 0 4% 0 4%;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #151436;
}

.tg-button-mobile {
  /*color: #ffffff;*/
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
}
