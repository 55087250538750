.App {
  /*text-align: center; */
  background-color: #ffffff; /*#fbd436;*/
}

.Buttons {
  display: flex;
  flex-direction: column;
  margin: 0 8px 0 8px;
}

.Button {
  margin: 8px 0px 8px 0px;
  width: 7em;
}

.Button-slim {
  width: 6.2em;
}

.Buttons-container {
  display: flex;
  position: relative;
  left: -10px;
  flex-direction: row;
  align-items: start;
  justify-content: start;
}

.button-box {
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-self: center;
  margin: 3em 2em 0 6em;
}

.logo {
  #height: 40vmin;
  #pointer-events: none;
  margin: 0;
  /*background-color: pink; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.back-button {
  /*margin: 0% 5% 2%; */
  width: 18em;
  height: 3em;
  /*color: #ffffff;*/
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
}

.row-button-back {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff; /* #f5f5f5; #fbd436;*/

  /*background-image: url(./img/bg.svg); */

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.App-header__mobile {
  align-items: center;
}

.Img-iphone14 {
  margin-top: 1em;
}

.elipse {
  align-self: end;
  position: absolute;
  top: 2em;
  right: 0;
  z-index: 1;
}

.elipse2 {
  align-self: start;
  position: absolute;
  top: -3em;
  left: -7em;
  z-index: 1;
}

.elipse3 {
  position: absolute;
  bottom: 25%;
  right: 0em;
  z-index: 1;
}

.absolute-mockup {
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
}

.absolute-mockup2 {
  position: relative;
  /*top: -3em;
  left: -3em;*/
  z-index: 2;
}

.absolute-mockup3 {
  position: relative;
  top: -3em;
  left: -2em;
  z-index: 2;
}

.absolute-mockup4 {
  position: relative;
  top: 0em;
  right: 5%;
  z-index: 2;
}

.row-container {
  display: flex;
  flex-direction: row;
  /* margin: 2px; */
  /* padding-left: 5%; */
  /* font-family: Consolas, Arial, sans-serif; */
  /* background-color: pink; */
}

.column-container {
  display: flex;
  flex-direction: column;
  /* font-family: Consolas, Arial, sans-serif; */
  /* background-color: pink; */
}

.flex3 {
  display: flex;
  flex: 3;
  flex-direction: column;
  /*background-color: yellow; */ 
  /*justify-content: center;*/
  /*align-items: center; */
}

.flex2 {
  display: flex;
  flex: 2;
  flex-direction: column;
  position: relative;
  /*background-color: green;*/

  /*justify-content: center;*/
  /*align-items: center; */
}

.flex25 {
  display: flex;
  flex: 2.5;
  flex-direction: column;
  position: relative;
  background-color: green;

  /*justify-content: center;*/
  /*align-items: center; */
}

.flex1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 2px 0 2px;
  /*justify-content: center;*/
  align-items: center;
}

.align-start {
  align-items: start;
}

.left-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* background-color: yellow; */
  /*margin: 5% 10%; */
  display: flex;
}

.mobile-container {
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Melper */

.melper {
  /*width: 249px;
  height: 70px; */

  /* Tablet/H1 */

  font-family: 'Druk Wide Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 70px;
  /* identical to box height, or 146% */

  /* text */

  color: #151436;
}

.melper-desktop {
  font-size: 64px;
}

/* Title  */

.title-app {
  /*width: 600px;
  height: 100px;
  */
  /* Desktop/H4 */

  /*font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  /* or 125% */

  /* text */

  color: #151436;
}

.title-item {
  margin: 0em 2em 0 0em;

  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 50px;
  color: #151436;
}

.context-item {
  margin: 1em 15% 0 0;

  /*font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;

  color: #151436;
}

.fm-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-context {
  font-style: normal;
  width: 70%;
  font-weight: 500;
  font-size: 64px;
  line-height: 70px;
  /* or 109% */

  text-align: center;
  text-transform: uppercase;

  color: #151436;
}

.doc-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  /*background-color: pink;*/
  /*justify-content: center;*/
}

.contact {
  display: flex;
  flex: 1.3;
  flex-direction: column;
  /*background-color: pink;*/
  /*justify-content: center;*/
}

.telegram-button {
  width: 28em;
  /*color: #ffffff;*/
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
}

.about {
  width: 16em;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #151436;
}

.doc-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #151436;
}

.doc {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #151436;
  text-align: start;
  text-decoration: none;
}

.back-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #4d6ce6;
}

.footer {
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #b8b8b8;
}

.footer-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #151436;
  text-decoration: none;
}

.html {
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  color: #151436;
  overflow: hidden;
}

.height-box {
  height: 26em;
}

.width-tg {
  width: 20rem;
}

.weight500 {
  font-weight: 500;
}

.red {
  color: #cd5b76;
}

.blue {
  color: #4d6ce6;
}

.mt-7 {
  margin-top: 7em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-1 {
  margin-top: 1em;
}

.mt-4 {
  margin-top: 4em;
}

.mt-5 {
  margin-top: 5em;
}

.ml-1 {
  margin-left: 5%;
}

.ml-2 {
  margin-left: 10%;
}

.mr-1 {
  margin-right: 5%;
}

.mr-2 {
  margin-right: 10%;
}
